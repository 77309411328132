// src/redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  userProfile: null,
  userPassword: null,
  userName: null,
};

// Create slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload.profile;
      state.userPassword = action.payload.password;
      state.userName = action.payload.username;
    },
    clearUserProfile: (state) => {
      state.userProfile = null;
      state.userPassword = null;
      state.userName = null;
    },
  },
});

// Export actions
export const { setUserProfile, clearUserProfile } = userSlice.actions;

// Export reducer
export default userSlice.reducer;
