// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import { loadState, saveState } from './persist';

// Load the persisted state
const persistedState = loadState();

const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: persistedState, // Initialize store with persisted state
});

// Subscribe to store updates and save the state to localStorage
store.subscribe(() => {
  saveState({
    user: store.getState().user, // Persist only the user slice
  });
});

export default store;
