import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputfieldCom from "../components/items/InputfieldCom";
import { Avatar, Box, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import ButtonMD from "../components/items/ButtonMD";
import { useNavigate } from "react-router-dom";
import url from "../url";
import Sidebar from "../components/sidebar/Sidebar";
import background from "../Assets/background.PNG";
import { io } from "socket.io-client";
import endpoint from "../Endpointurl";
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";

function Contactus() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [profiledetails, setProfiledetails] = useState("");

    // Simulated email value, you might get this from props, state, or an API call
    const userEmail = "user@example.com";

    const details = useSelector(state => state.user.userProfile);

    useEffect(() => {

        // const details = JSON.parse(localStorage.getItem('profiledetails'));

        if (details) {
            setProfiledetails(details);
        }

    }, []);

    const validationSchema = yup.object({
        firstname: yup
            .string('Enter your firstname')
            .required('First name is required'),
        // lastname: yup
        //     .string('Enter your lastname')
        //     .required('Last name is required'),
        message: yup
            .string('Enter your message')
            .required('Message is required'),
    });

    const formik = useFormik({
        initialValues: {
            firstname: '',
            // lastname: '',
            message: ''
        },
        validationSchema: validationSchema,

        onSubmit: (values, { resetForm }) => {
            console.log(values);

            setLoading(true);
            setTimeout(() => {
                var InsertAPIURL = `${url}contact_us/send_message`
                var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                };
                var Data = {
                    "first_name": values.firstname,
                    "last_name": values.lastname,
                    "email": profiledetails?.data?.email,
                    "message": values.message

                };
                fetch(InsertAPIURL, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(Data),
                })
                    .then(response => response.json())
                    .then(response => {
                        setLoading(false);
                        console.log(response)
                        if (response.error) {
                            setLoading(false);
    toastAlert("error",response.message);

                          
                        } else {

                            // navigate(`${endpoint}playgame`)
                            toastAlert("success",response.message);
                          

                            setTimeout(() => {
                                setLoading(false);
                                formik.resetForm();
                            }, 2000)


                        }
                    }
                    )
                    .catch(error => {
                        setLoading(false);
                        toastAlert("error","Something went wrong");

                    });
            }, 2000)

        },
    });



    return (
        <>
            <Sidebar
                componentData={
                    <Box
                        sx={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100vh",
                            overflow: "hidden"
                        }}
                    >
                        <Typography variant='h6' color="#F5BC01" align="center" fontFamily="Pacifico" fontSize={{ xs: "27px", sm: "37px", md: "57px" }} pt={4} mb={1}  >
                            {t("Contact Us")}
                        </Typography>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
                            <Card sx={{ mt: { xs: 0, md: 0 }, p: 0, backgroundColor: "transparent", borderRadius: "10px", boxShadow: "none", border: "1px solid transparent", width: { xs: "90%", md: "50%" } }}>
                                <CardContent>

                                    <form onSubmit={formik.handleSubmit} >
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} md={12}>
                                                <Stack direction="column">

                                                    <Stack direction="row" spacing={2} width="100%">
                                                        <div style={{ marginBottom: "5px" }}>
                                                            <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize={{ xs: "13px", md: "17px" }} mb={1}>
                                                                {t("First Name")}
                                                            </Typography>
                                                            <InputfieldCom
                                                                autoFocus={false}
                                                                value={formik.values.firstname}
                                                                onChngeterm={(e) => formik.setFieldValue("firstname", e.target.value)}
                                                                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                                                helperText={formik.touched.firstname && formik.errors.firstname}
                                                                type="text"
                                                                variant="outlined"
                                                                label=""
                                                            />
                                                        </div>

                                                        <Box sx={{ flexGrow: 1 }} />

                                                        <div style={{ marginBottom: "5px" }}>
                                                            <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize={{ xs: "13px", md: "17px" }} mb={1}>
                                                                {t("Last Name")}
                                                            </Typography>
                                                            <InputfieldCom
                                                                autoFocus={false}
                                                                value={formik.values.lastname}
                                                                onChngeterm={(e) => formik.setFieldValue("lastname", e.target.value)}
                                                                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                                                helperText={formik.touched.lastname && formik.errors.lastname}
                                                                type="text"
                                                                variant="outlined"
                                                                label=""
                                                            />
                                                        </div>
                                                    </Stack>

                                                    <div style={{ marginBottom: "5px" }}>
                                                        <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize={{ xs: "13px", md: "17px" }} mb={1}>
                                                            {t("Email")}
                                                        </Typography>
                                                        <InputfieldCom
                                                            autoFocus={false}
                                                            value={`${profiledetails?.data?.email}`}
                                                            // onChngeterm={(e) => formik.setFieldValue("email", e.target.value)}
                                                            // error={formik.touched.email && Boolean(formik.errors.email)}
                                                            // helperText="You cannot update your email address"
                                                            type="text"
                                                            variant="outlined"
                                                            label=""
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    <div style={{ marginBottom: "5px" }}>
                                                        <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize={{ xs: "13px", md: "17px" }} mb={1}>
                                                            {t("Message")}
                                                        </Typography>
                                                        <InputfieldCom
                                                            autoFocus={false}
                                                            value={formik.values.message}
                                                            onChngeterm={(e) => formik.setFieldValue("message", e.target.value)}
                                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                                            helperText={formik.touched.message && formik.errors.message}
                                                            type="text"
                                                            variant="outlined"
                                                            multiline
                                                            rows={4}
                                                            label=""
                                                        />
                                                    </div>

                                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "5px" }}>
                                                        <ButtonMD variant="contained" title={t("Send")} width="50%" type="submit" borderColor="orange" backgroundColor="orange" borderRadius="10px" disabled={loading} onClick={formik.handleSubmit} />
                                                    </div>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </form>

                                </CardContent>
                            </Card>
                        </div>
                    </Box>
                }
            />
        </>
    );
}

export default Contactus;
