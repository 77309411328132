import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom"; 

const PrivateRoute = ({ children }) => {

  const username = useSelector(state => state.user.userName);

  // Use the username to check if the user is authenticated
  const isAuthenticated = () => {
    return username; // Return true if username exists

    // return localStorage.getItem("username");

  };

  return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
