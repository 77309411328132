import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Emailverification from "./pages/Emailverification";
import OtpVerification from "./pages/Otpverification";
import SetPassword from "./pages/Setpassword";
import Dashboard from "./pages/Dasboard";
import Winner from "./pages/Winner";
import Wallet from "./pages/Wallet";
import Paypalsuccess from "./pages/Paypalsuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import UpdatePassword from "./pages/Updatepassword";
import Termsandconditions from "./pages/Termsandconditions";
import Editprofile from "./pages/Editprofile";
import Editpassword from "./pages/Editpassword";
import Deleteaccount from "./pages/Deleteaccount";
import Contactus from "./pages/Contactus";
import History from "./pages/History";
import endpoint from "./Endpointurl";
import Paypalcancel from "./pages/Paypalcancel";
import CurrentGame from "./pages/CurrentGame";
import PrivateRoute from "./pages/PrivateRoute";
import PublicRoute from "./pages/PublicRoute";
import url from "./url";
import { io } from "socket.io-client";

function App() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);

  //   const token = urlParams.get('token');

  //   console.log("main main", token);

  //   if (token) {
  //     navigate(`${endpoint}wallet`);
  //   }
  // }, []);

  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
      // Initialize the socket connection
      const socket = io(url);

      // Define the message listener
      const messageListener = (msg) => {
          console.log("msg", msg);
          setStatus(msg);

          switch (msg.status) {
              case "created":
                  console.log("game-created"); // show triangle screen
                  navigate(`${endpoint}current_game`);
                  break;
              case "waiting":
                  console.log("game-status-change"); // show waiting screen ss in phone if status is waiting
                  navigate(`${endpoint}current_game`);
                  break;
              case "started":
                  console.log("game-started"); // if status is started then show animation
                  navigate(`${endpoint}current_game`);
                  break;
              case "result-anounced":
                  console.log("result-anounced");
                  navigate(`${endpoint}winner`);
                  break;
              case "restart":
                  console.log("game-restart"); // show restart game screen ss in phone
                  navigate(`${endpoint}current_game`);
                  break;
              case "added-participants":
                  console.log("added-participants");
                  break;
              case "deleted":
                  console.log("game-deleted");
                  navigate(`${endpoint}dashboard`);
                  break;
              case "scheduled":
                  console.log("game-scheduled");
                  navigate(`${endpoint}current_game`);
                  break;
              default:
                  console.log("Unknown status");
          }

          console.log(":ddggfgf");
      };

      // Set up the socket event listener
      socket.on("received-data", messageListener);

      // Cleanup function to remove the message listener and disconnect socket
      return () => {
          socket.off("received-data", messageListener);
          socket.disconnect();
      };
  }, [status]);
  return (
    <>
      <Routes>
        <Route path={`${endpoint}`} element={ <PublicRoute><Login /></PublicRoute>} />
        <Route path={`${endpoint}signup`} element={ <PublicRoute><Signup /></PublicRoute>} />
        <Route
          path={`${endpoint}emailverification`}
          element={ <PublicRoute><Emailverification /></PublicRoute>}
        />
        <Route
          path={`${endpoint}otpverification`}
          element={<OtpVerification />}
        />
        <Route path={`${endpoint}setpassword`} element={<SetPassword />} />

        <Route
          path={`${endpoint}dashboard`}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* <Route path={`${endpoint}playgame`} element={<Playgame />} /> */}
        <Route
          path={`${endpoint}current_game`}
          element={
            <PrivateRoute>
              <CurrentGame />
            </PrivateRoute>
          }
        />

        <Route
          path={`${endpoint}winner`}
          element={
            <PrivateRoute>
              <Winner />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}wallet`}
          element={
            <PrivateRoute>
              <Wallet />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}success`}
          element={
            <PrivateRoute>
              <Paypalsuccess />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}privacypolicy`}
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}termsconditions`}
          element={
            <PrivateRoute>
              <TermsConditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}updatepassword`}
          element={ <PublicRoute><UpdatePassword /></PublicRoute>}
        />
        <Route
          path={`${endpoint}termsandconditions`}
          element={
            <PrivateRoute>
              <Termsandconditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}history`}
          element={
            <PrivateRoute>
              <History />
            </PrivateRoute>
          }
        />
        {/* <Route path={`${endpoint}entryconfirm`} element={<Entryconfirm />} /> */}
        {/* <Route path={`${endpoint}waiting`} element={<Waiting />} /> */}
        {/* <Route path={`${endpoint}gamestarted`} element={<Gamestarted />} /> */}
        {/* <Route path={`${endpoint}restart`} element={<Restartgame />} /> */}
        {/* <Route path={`${endpoint}pickball`} element={<Pickball />} /> */}
        <Route
          path={`${endpoint}editprofile`}
          element={
            <PrivateRoute>
              <Editprofile />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}changepassword`}
          element={
            <PrivateRoute>
              <Editpassword />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}deleteaccount`}
          element={
            <PrivateRoute>
              <Deleteaccount />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}contactus`}
          element={
            <PrivateRoute>
              <Contactus />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}cancel`}
          element={
            <PrivateRoute>
              <Paypalcancel />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
