import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Sidebar from "../components/sidebar/Sidebar";
import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, Modal, OutlinedInput, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import TypographyMD from "../components/items/Typography";
import endpoint from "../Endpointurl";
import { useNavigate } from "react-router-dom";
import Topbar from "../components/topbar/Topbar";
import url from "../url";
import background from "../Assets/background.PNG";
import balls from "../Assets/balls.png";
import io from 'socket.io-client';
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";

function Dashboard() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = useState(true);
    const details = useSelector(state => state.user.userProfile);

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [profiledetails, setProfiledetails] = useState('');
    // const [balls, setBalls] = useState([]);
    const [game, setGame] = useState([]);
    const [selectedBall, setSelectedBall] = useState(null);
    const [userdetails, setUserdetails] = useState("");

    // const getScheduleGame = (details) => {

    //     var InsertAPIURL = `${url}game/get_scheduled_games?user_id=${details?.data?.user_id}`
    //     var headers = {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //     };
    //     console.log("InsertAPIURL",InsertAPIURL)
    //     fetch(InsertAPIURL, {
    //         method: 'GET',
    //         headers: headers,
    //         body: JSON.stringify(),
    //     })
    //         .then(response => response.json())
    //         .then(async response => {

    //             console.log("game", response);
    //             setLoader(false);
    //             return response;
    //             // if (response?.error === true) {
    //             //     // alert("Error")
    //             //    await getUserDetails(details);
    //             // }else{
    //             //     navigate(`${endpoint}current_game`);
    //             // }
            

    //             // if (response?.data == null || undefined || response?.data?.length == 0) {
    //             //     navigate(`${endpoint}dashboard`);
    //             // }
    //             // // else if (response.data[0].game_status == "waiting") {
    //             // //     navigate(`${endpoint}waiting`);
    //             // // } else if (response.data[0].game_status == "started") {
    //             // //     navigate(`${endpoint}gamestarted`);
    //             // // }
    //             // //  else if (response.data[0].user_selcted_ball >= 1&&response.data[0].game_status == "scheduled") {
    //             // //     navigate(`${endpoint}restart`);
    //             // // }   
    //             // else {
    //             //     navigate(`${endpoint}current_game`);
    //             // }
    //             // 
    //         }
    //         )
    //         .catch(error => {
    //             // setLoading(false);
    //             toastAlert("error", "Something went wrong. Please try again later.");

    //             setLoader(false);
    //         });


    // }

    // const getUserDetails = (details) => {

    //     var InsertAPIURL = `${url}transaction_history/get_wallet_value_by_user_id?user_id=${details.data.user_id}`
    //     var headers = {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //     };
    //     console.log("InsertAPIURL",InsertAPIURL)
    //     fetch(InsertAPIURL, {
    //         method: 'GET',
    //         headers: headers,
    //         body: JSON.stringify(),
    //     })
    //         .then(response => response.json())
    //         .then(response => {

    //             console.log("user", response);
    //             // setUserdetails(response);
    //             return response;

    //             // if (response.data[0] == null || undefined) {
    //             //     navigate(`${endpoint}dashboard`);
    //             // } else {
    //             //     navigate(`${endpoint}playgame`);
    //             // }
    //         }
    //         )
    //         .catch(error => {
    //             // setLoading(false);
    //             toastAlert("error", "Something went wrong. Please try again later.");

    //         });


    // }


    // useEffect(() => {

    //     const details = JSON.parse(localStorage.getItem('profiledetails'));
    //     if (details) {
    //         setProfiledetails(details);
    //     }

    //     getScheduleGame(details);
    //     getUserDetails(details);

    // }, []);




    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoader(false);
    //     }, 2000); // 2 seconds

    //     return () => clearTimeout(timer); // Cleanup the timer on unmount
    // }, []);
    const getScheduleGame = (details) => {
        const InsertAPIURL = `${url}game/get_scheduled_games?user_id=${details?.data?.user_id}`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    
        console.log("InsertAPIURL", InsertAPIURL);
    
        return fetch(InsertAPIURL, {
            method: 'GET',
            headers: headers,
        })
            .then(response => response.json())
            .then(response => {
                console.log("game", response);
                // setLoader(false); // Ensure this updates correctly in your component
                return response; // Return the response for use in Promise.all
            })
            .catch(error => {
                console.error("Error in getScheduleGame:", error);
                toastAlert("error", "Something went wrong. Please try again later.");
                setLoader(false);
                throw error; // Throw the error to handle it in Promise.all
            });
    };
    
    const getUserDetails = (details) => {
        const InsertAPIURL = `${url}transaction_history/get_wallet_value_by_user_id?user_id=${details.data.user_id}`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    
        console.log("InsertAPIURL", InsertAPIURL);
    
        return fetch(InsertAPIURL, {
            method: 'GET',
            headers: headers,
        })
            .then(response => response.json())
            .then(response => {
                console.log("user", response);
                return response; // Return the response for use in Promise.all
            })
            .catch(error => {
                console.error("Error in getUserDetails:", error);
                toastAlert("error", "Something went wrong. Please try again later.");
                throw error; // Throw the error to handle it in Promise.all
            });
    };

    useEffect(() => {
        const fetchDetails = () => {
            if (details) {
                setProfiledetails(details); // Set the profile details state
                console.log("details redux", details);
            }
    
            // Use Promise.all to execute multiple promises
            Promise.all([
                getScheduleGame(details),
                getUserDetails(details)
            ])
                .then(([scheduleGameResponse, userDetailsResponse]) => {
                    console.log("Schedule Game Response:", scheduleGameResponse);
                    console.log("User Details Response:", userDetailsResponse);

                    if(scheduleGameResponse?.error===true){
                        setUserdetails(userDetailsResponse);
                    }else{
                        navigate(`${endpoint}current_game`);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    console.error("Error fetching details:", error);
                });
        };
    
        // const fetchDetails = async () => {

        //     // const details = JSON.parse(localStorage.getItem('profiledetails'));

        //     // if (details) {
        //     //     setProfiledetails(details);
        //     // } 

        //     // If the profile exists in Redux
        //     if (details) {
        //         setProfiledetails(details);  // Set the profile details state
        //         console.log("details redux", details);
        //     }

        //     try {
        //         await getScheduleGame(details); // Await the completion of getScheduleGame
        //         // await getUserDetails(details);  // Await the completion of getUserDetails
        //     } catch (error) {
        //         console.error("Error fetching details:", error);
        //     }
        // };

        fetchDetails();
    }, []);
    return (
        <>
            <Sidebar
                componentData={
                    <Box
                        sx={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100vh",
                        }}
                    >
                        {loader ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                                <Card sx={{ mt: { xs: 0, md: 5 }, p: 0, borderRadius: "10px", boxShadow: "none", border: "1px solid #F5BC01", width: { xs: "90%", md: "50%" } }}>
                                    <CardContent>
                                        <Grid container spacing={0}>
                                            <Grid xs={6} md={6}>
                                                <Stack direction="column">
                                                    <TypographyMD variant='paragraph' label={t("Available Balance")} color="#000000" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="left" />

                                                    <TypographyMD variant='paragraph' label={t("Total Played Games")} color="#000000" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="left" />

                                                    <TypographyMD variant='paragraph' label={t("Won Games")} color="#000000" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="left" />

                                                    <TypographyMD variant='paragraph' label={t("Lost Games")} color="#000000" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="left" />
                                                </Stack>
                                            </Grid>

                                            <Grid xs={6} md={6}>
                                                <Stack direction="column">
                                                    <TypographyMD variant='paragraph' label={`$ ${userdetails?.total_balance}`} color="#F5BC01" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="right" />

                                                    <TypographyMD variant='paragraph' label={userdetails?.total_played_games} color="#F5BC01" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="right" />

                                                    <TypographyMD variant='paragraph' label={userdetails?.total_won_games} color="#F5BC01" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="right" />

                                                    <TypographyMD variant='paragraph' label={userdetails?.total_lose_games} color="#F5BC01" marginLeft={0} fontFamily="Rubik" fontSize="16px" fontWeight={450} align="right" />

                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <img src={balls} alt="Balls" style={{ width: "50vh", marginBottom: '20px' }} />

                                <Typography
                                    variant='h6'
                                    color="#F5BC01"
                                    fontFamily="Pacifico"
                                    fontSize="30px"
                                    sx={{
                                        width: { xs: "90%", md: '30%' },
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {t("No games yet. 🎱 Get ready for action! Stay tuned. 🌟🔄")}
                                </Typography>
                            </div>
                        )}
                    </Box>
                }
            />

        </>
    )
}

export default Dashboard;