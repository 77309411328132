// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// const username = useSelector(state => state.user.userName);
// // Assuming you have an authentication state (from context or Redux)
// const isAuthenticated = () => {
//   return username; // Example: check token in localStorage

//   // localStorage.getItem("username");

// };

// const PublicRoute = ({ children }) => {
//   return !isAuthenticated() ? children : <Navigate to="/dashboard" />; // Redirect to dashboard if authenticated
// };

// export default PublicRoute;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {

  const username = useSelector(state => state.user.userName);

  // Use the username to check if the user is authenticated
  const isAuthenticated = () => {
    return username; // Return true if username exists
  };

  return !isAuthenticated() ? children : <Navigate to="/dashboard" />;
};

export default PublicRoute;

