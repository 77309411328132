import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputfieldCom from "../components/items/InputfieldCom";
import { Avatar, Box, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import ButtonMD from "../components/items/ButtonMD";
import { useNavigate } from "react-router-dom";
import url from "../url";
import Sidebar from "../components/sidebar/Sidebar";
import background from "../Assets/background.PNG";
import endpoint from "../Endpointurl";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../redux/userSlice';

function Editprofile() {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profiledetails, setProfiledetails] = useState("");
    const [username, setUsername] = useState("");
    const { t, i18n } = useTranslation();

    // Simulated email value, you might get this from props, state, or an API call
    const userEmail = "user@example.com";

    const details = useSelector(state => state.user.userProfile);
    const userName = useSelector(state => state.user.userName);

    useEffect(() => {

        // const details = JSON.parse(localStorage.getItem('profiledetails'));
        if (details) {
            setProfiledetails(details);
        }

        // const userName = JSON.parse(localStorage.getItem('username'));
        if (userName) {
            setUsername(userName);
        }

    }, []);

    const validationSchema = yup.object({
        username: yup
            .string('Enter your username')
            .required('Username is required'),
    });

    const formik = useFormik({
        initialValues: {
            username: username || ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,

        onSubmit: (values, { resetForm }) => {
            console.log(values);

            setLoading(true);
            setTimeout(() => {
                var InsertAPIURL = `${url}user/update_user_name`
                var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                };
                var Data = {
                    "user_id": profiledetails?.data?.user_id,
                    "user_name": values.username
                };
                fetch(InsertAPIURL, {
                    method: 'PUT',
                    headers: headers,
                    body: JSON.stringify(Data),
                })
                    .then(response => response.json())
                    .then(response => {
                        setLoading(false);
                        console.log(response)
                        if (response.error) {
                            setLoading(false);
                            toastAlert("error", response.message);

                        } else {

                            // navigate(`${endpoint}playgame`)
                            toastAlert("success", response.message);

                            // localStorage.setItem('username', JSON.stringify(values.username));

                            dispatch(setUserProfile({
                                // profile: response,
                                // password: values.password,
                                username: values?.username,
                            }));

                            window.location.reload();
                            setTimeout(() => {
                                setLoading(false);
                                formik.resetForm();
                            }, 2000)

                        }
                    }
                    )
                    .catch(error => {
                        setLoading(false);
                        toastAlert("error", "Something went wrong. Please try again later");

                    });
            }, 2000)

        },
    });



    return (
        <>
            <Sidebar
                componentData={
                    <Box
                        sx={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100vh",
                            overflow: "hidden"
                        }}
                    >
                        <Container>
                            <Typography variant='h6' color="#F5BC01" align="center" fontFamily="Pacifico" fontSize={{ xs: "27px", sm: "37px", md: "57px" }} pt={4} mb={1}  >
                                {t("Edit Profile")}
                            </Typography>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '60vh' }}>
                                <Card sx={{ mt: { xs: 0, md: 5 }, p: 0, backgroundColor: "transparent", borderRadius: "10px", boxShadow: "none", border: "1px solid transparent", width: { xs: "90%", md: "50%" } }}>
                                    <CardContent>

                                        <form onSubmit={formik.handleSubmit} >
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} md={12}>
                                                    <Stack direction="column">
                                                        <div style={{ marginBottom: "10px" }}>
                                                            <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize="17px" mb={1}>
                                                                {t("Username")}
                                                            </Typography>
                                                            <InputfieldCom
                                                                autoFocus={false}
                                                                value={formik.values.username}
                                                                onChngeterm={(e) => formik.setFieldValue("username", e.target.value)}
                                                                error={formik.touched.username && Boolean(formik.errors.username)}
                                                                helperText={formik.touched.username && formik.errors.username}
                                                                type="text"
                                                                variant="outlined"
                                                                label=""
                                                            />
                                                        </div>

                                                        <div style={{ marginBottom: "10px" }}>
                                                            <Typography variant='h6' color="gray" align="left" fontFamily="Rubik" fontSize="17px" mb={1}>
                                                                {t("Email")}
                                                            </Typography>
                                                            <InputfieldCom
                                                                autoFocus={false}
                                                                value={`${profiledetails?.data?.email}`}
                                                                // onChngeterm={(e) => formik.setFieldValue("email", e.target.value)}
                                                                // error={formik.touched.email && Boolean(formik.errors.email)}
                                                                helperText="You cannot update your email address"
                                                                type="text"
                                                                variant="outlined"
                                                                label=""
                                                                disabled={true}
                                                            />
                                                        </div>

                                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "30px" }}>
                                                            <ButtonMD variant="contained" title={t("Edit Profile")} width={{ xs: "90%", md: "50%" }} type="submit" borderColor="orange" backgroundColor="orange" borderRadius="10px" disabled={loading} onClick={formik.handleSubmit} />
                                                        </div>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </form>

                                    </CardContent>
                                </Card>
                            </div>
                        </Container>
                    </Box>
                }
            />
        </>
    );
}

export default Editprofile;
